import { z } from 'zod';

export const SENTRY_DSN =
  'https://ed32f066daf0bd7fd7e7451ec376a6f4@o4504173136510976.ingest.us.sentry.io/4507649716912128';

const clientConfigSchema = z.object({
  NODE_ENV: z.enum(['production', 'development']),
});

export const CLIENT_CONFIG = clientConfigSchema.parse({
  NODE_ENV: import.meta.env.MODE,
});

export type ClientConfig = z.infer<typeof clientConfigSchema>;

export const isDevelopment = CLIENT_CONFIG.NODE_ENV === 'development';
export const isProduction = CLIENT_CONFIG.NODE_ENV === 'production';
